import { FC } from 'react';
import IconProps from './IconProps';

const HelpCircleIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.9994" cy="8.00086" r="6.00086" stroke="currentColor" strokeWidth="1.33353" />
      <path
        d="M9.10066 4C8.08718 4 7.32974 4.52274 6.99902 5.38687L7.67112 5.70691C7.92716 5.12016 8.42856 4.78945 9.06865 4.78945C9.86877 4.78945 10.4982 5.41887 10.4982 6.17631C10.4982 7.07244 9.78342 7.75521 8.60992 7.76588L8.70594 9.20608H9.39937L9.48471 8.29929C10.5515 8.13926 11.3303 7.27514 11.3303 6.18698C11.3303 4.97081 10.4342 4 9.10066 4ZM9.05799 10.6623C8.74861 10.6623 8.49257 10.9183 8.49257 11.2277C8.49257 11.5478 8.73794 11.7931 9.05799 11.7931C9.36736 11.7931 9.6234 11.5478 9.6234 11.2277C9.6234 10.9183 9.36736 10.6623 9.05799 10.6623Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.666763"
      />
    </svg>
  );
};

export default HelpCircleIcon;
